.ui-datepicker {
    margin: 5px auto 0;
    font-size: 1em;
    background-color: var(--dogi-teal-dark-opq);
    border-radius: 10px;
}

.ui-datepicker table {
    width: 100%;
    background-color: var(--dogi-modal-bkg);
    border-radius: 10px;
}

.ui-datepicker-header {
    background: var(--dogi-teal-main);
    color: var(--dogi-gold-light);
    font-family:'Times New Roman';
    /* border-width: 1px 0 0 0;
    border-style: solid;
    border-color: var(--dogi-teal-main); */
    border-radius: 10px;
}

.ui-datepicker-title {
    text-align: center;
    font-size: 15px;

}

.ui-datepicker-prev {
    float: left;
    cursor: pointer;
}

.ui-datepicker-next {
    float: right;
    cursor: pointer;
    background-position: center 0px;
}

.ui-datepicker thead {
    background-color: var(--dogi-modal-bkg);

    /*border-bottom: 1px solid #bbb;*/
}

.ui-datepicker th {
    background-color:var(--dogi-teal-dark-opq);
    text-transform: uppercase;
    font-size: 8pt;
    color: var(--dogi-gold-light);
    /*text-shadow: 1px 0px 0px #fff;*/
    /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
}

.ui-datepicker tbody td {
    padding: 0;
    background-color:var(--dogi-modal-bkg);
    
}

.ui-datepicker tbody td:last-child {
    border-right: 0px;
}

.ui-datepicker tbody tr {
    border-bottom: 1px solid #bbb;
}

.ui-datepicker tbody tr:last-child {
    border-bottom: 0px;
}

.ui-datepicker a {
    text-decoration: none;
}

.ui-datepicker td span, .ui-datepicker td a {
    display: inline-block;
    /*font-weight: bold;*/
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    /*text-shadow: 1px 1px 0px #fff;*/
    /*filter: dropshadow(color=#fff, offx=1, offy=1);*/
}

.ui-datepicker-calendar .ui-state-default {
      background-color: var(--dogi-modal-bkg);
      color:#ffffff;


}

.ui-datepicker-calendar .ui-state-hover {
    background: var(--dogi-teal-main);
    color: #FFFFFF;
}

.ui-datepicker-calendar .ui-state-active {
    background: var(--dogi-teal-main);
    color: #e0e0e0;
    border: 1px solid #55838f;
    position: relative;
    margin: -1px;
}

.ui-datepicker-unselectable .ui-state-default {
    background: --var(--dogi-gold-dark-opq);
    color: --var(--dogi-gold-dark);
}

.ui-datepicker-title >  select {
    background-color: var(--dogi-gold-light);
}